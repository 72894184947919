var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detail-box", style: _vm.style }, [
    _c("div", { staticClass: "list-item" }, [
      _c("div", { staticClass: "content-left" }, [
        _c("div", { staticClass: "detail-l" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.item.companyName)),
          ]),
          _c("div", { staticClass: "address" }, [
            _c("svg", { staticClass: "iconpark-icon iconColor" }, [
              _c("use", { attrs: { href: "#local-two-5mga1aal" } }),
            ]),
            _c("span", { staticClass: "cityName" }, [
              _vm._v(_vm._s(_vm.item.cityName)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "detail-list" },
          _vm._l(_vm.billOptions, function (option, billIndex) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item[option.key],
                    expression: "item[option.key]",
                  },
                ],
                key: billIndex,
                staticClass: "detail",
              },
              [
                _c("div", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(option.label) + "(" + _vm._s(option.company) + ")："
                  ),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("stateFormatNum")(_vm.item[option.key])) +
                      " "
                  ),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "detail-r" },
        [
          _c(
            "a-dropdown",
            { attrs: { placement: "bottomLeft" } },
            [
              _c("a-button", { attrs: { prefix: "daochu", suffix: "left" } }, [
                _vm._v("导出"),
              ]),
              _c(
                "a-menu",
                { attrs: { slot: "overlay" }, slot: "overlay" },
                [
                  _c("a-menu-item", { on: { click: _vm.exportBill } }, [
                    _vm._v(" 导出明细 "),
                  ]),
                  _c("a-menu-item", { on: { click: _vm.exportFile } }, [
                    _vm._v(" 导出申报文件 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "ant-button",
              attrs: { prefix: "daoru" },
              on: { click: _vm.importBill },
            },
            [_vm._v(" 导入 ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "ant-button",
              attrs: { prefix: "chakan-chakanmingxi" },
              on: {
                click: function ($event) {
                  return _vm.lookBill(_vm.item)
                },
              },
            },
            [_vm._v(" 查看账单 ")]
          ),
          _c(
            "a-button",
            {
              staticClass: "ant-button",
              attrs: { type: "link", prefix: _vm.flag ? "shouqi" : "zhankai" },
              on: { click: _vm.show },
            },
            [_vm._v(" " + _vm._s(_vm.flag ? "收起" : "展开") + " ")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "tenantDetail" },
      [
        _c(
          "ul",
          { staticClass: "detail-head head-border" },
          _vm._l(_vm.monthBillDetail, function (head, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(head.label))])
          }),
          0
        ),
        _vm._l(_vm.insureClassifyList, function (detail, index) {
          return [
            _c("ul", { key: index, staticClass: "detail-head" }, [
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(detail.insureClassifyName)),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(detail.increaseCount)),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(detail.decreasesCount)),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(detail.personCount)),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(detail.supplyCount)),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(_vm._f("stateFormatNum")(detail.companyTotal))),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(_vm._f("stateFormatNum")(detail.personTotal))),
              ]),
              _c("li", { attrs: { span: 3 } }, [
                _vm._v(_vm._s(_vm._f("stateFormatNum")(detail.total))),
              ]),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }